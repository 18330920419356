// src/components/JobDescription.js
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getInterviewDetails } from '../services/api';
import Markdown from 'react-markdown';

function JobDescription() {
  const { id } = useParams();
  const [interviewDetails, setInterviewDetails] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInterview = async () => {
      const fetchedInterview = await getInterviewDetails(id);
      setInterviewDetails(fetchedInterview);
    };
    fetchInterview();
  }, []);

  return (
    <div className="flex items-center justify-center h-screen w-screen bg-blue-800 text-white">
      <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <div
          className="bg-white text-black rounded-lg shadow-lg max-w-screen-lg w-2/3 h-2/3 flex flex-col overflow-hidden"
        >
          {/* Title */}
          <div className="p-4 sticky top-0 z-10">
            <h1 className="text-2xl font-bold">{interviewDetails?.job.title}</h1>
          </div>

          {/* Scrollable Content */}
          <div className="p-4 overflow-y-auto flex-1">
            <Markdown>{interviewDetails?.job.description}</Markdown>
          </div>

          {/* Footer with Go Back Button */}
          <div className="p-4 sticky bottom-0 z-10 flex justify-end">
            <button
              className="px-4 py-2 bg-red-600 text-white rounded-md shadow-md"
              onClick={() => navigate(`/`)}
            >
              Go Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobDescription;
