import React from 'react';

const DescriptionPopup = ({ title, content, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
      <div className="bg-gray-800 text-white rounded-lg p-6 w-4/5 max-w-2xl shadow-lg">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">{title}</h2>
          <button
            className="text-gray-400 hover:text-white text-2xl"
            onClick={onClose}
          >
            &times;
          </button>
        </div>
        <div className="overflow-y-auto max-h-96 text-gray-300">
          <p className="whitespace-pre-wrap">{content}</p>
        </div>
        <div className="flex justify-end mt-4">
          <button
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default DescriptionPopup;
