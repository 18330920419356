import React, { useState } from 'react';
import { useDisconnectButton } from '@livekit/components-react';
import ConfirmationPopup from './ConfirmationPopup';
import { useNavigate } from 'react-router-dom';

const CustomDisconnectButton = () => {
  const [showConfirm, setShowConfirm] = useState(false);
  const navigate = useNavigate();

  const { buttonProps } = useDisconnectButton({ stopTracks: true });

  const handleButtonClick = () => {
    setShowConfirm(true);
  };

  const handleConfirm = () => {
    setShowConfirm(false);
    if (buttonProps?.onClick) {
      buttonProps.onClick();
      navigate('/thanks');
    }
  };

  const handleCancel = () => {
    setShowConfirm(false);
  };

  return (
    <>
      <button className="px-6 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 focus:outline-none" onClick={handleButtonClick}>
        Leave Room
      </button>
      {showConfirm && (
        <ConfirmationPopup
          message="Are you sure you want to end the interview?"
          onConfirm={() => {
            handleConfirm();
            setShowConfirm(false);
          }}
          onCancel={() => setShowConfirm(false)}
        />
      )}
    </>
  );
};

export default CustomDisconnectButton;
