import {
  ControlBar,
  GridLayout,
  LiveKitRoom,
  ParticipantTile,
  RoomAudioRenderer,
  useTracks,
} from '@livekit/components-react';
import { Track } from 'livekit-client';
import React, { useEffect, useState } from 'react';
import CustomDisconnectButton from './CustomDisconnectButton';
import { useParams } from 'react-router-dom';
import { getInterviewDetails, getToken } from '../services/api';
import DescriptionPopup from './DescriptionPopup';
import ParticipantAvatar from './ParticipantAvatar';

const NewInterview = () => {
  const { id } = useParams();
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [interviewDetails, setInterviewDetails] = useState(null);
  const [showDescriptionPopup, setShowDescriptionPopup] = useState(false);

  const getInitials = (name) => {
    if (name) {
      return name
        .split(' ')
        .map((namePart) => namePart.charAt(0).toUpperCase())
        .join('');
    } else {
      return '';
    }
  };

  useEffect(() => {
    const fetchTokenAndInterview = async () => {
      try {
        const fetchedToken = await getToken(id);
        const fetchedInterview = await getInterviewDetails(id);
        setToken(fetchedToken);
        setInterviewDetails(fetchedInterview);
      } catch (err) {
        setError('Failed to fetch token. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchTokenAndInterview();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="flex h-screen bg-blue-900 relative">
      <LiveKitRoom
        video={false}
        audio={true}
        token={token}
        serverUrl={process.env.REACT_APP_LIVEKIT_URL}
        data-lk-theme="default"
        style={{ height: '100vh', width: '100vw' }}
        className='w-screen h-screen flex'
      >
        <div className="flex-1 flex flex-col items-center justify-center bg-blue-800 relative">
          <ParticipantAvatar type="agent" label="L" color="blue" />
          {interviewDetails && (
            <div className="text-white text-center mt-4">
              <p className="font-semibold text-lg">Lydia</p>
              <p className="text-sm font-light">AI Interview Expert</p>
            </div>
          )}
        </div>

        {/* Right Column */}
        <div className="flex-1 flex flex-col items-center justify-center bg-blue-800 relative">
          <ParticipantAvatar
            type="user"
            label={getInitials(interviewDetails?.candidate?.name)}
            color="green"
          />
          {interviewDetails && (
            <div className="text-white text-center mt-4">
              <p className="font-semibold text-lg">
                {interviewDetails.candidate.name}
              </p>
              <p className="text-sm font-light">
                {interviewDetails.candidate.designation}
              </p>
            </div>
          )}
        </div>
        {/* <MyVideoConference /> */}
        <RoomAudioRenderer />
        {/* <ControlBar /> */}
        <div className="absolute bottom-16 left-1/2 transform -translate-x-1/2">
          <CustomDisconnectButton />
        </div>
      </LiveKitRoom>
      {interviewDetails && (
        <div className="absolute top-8 left-1/2 transform -translate-x-1/2 bg-black bg-opacity-50 text-white p-6 rounded-lg shadow-lg max-w-3xl text-center">
          <h1 className="text-2xl font-bold mb-2">{interviewDetails.title}</h1>
          <p className="text-lg font-light mb-2">
            {interviewDetails.job.position} at{' '}
            {interviewDetails.job.organization},{' '}
            {interviewDetails.job.location}
          </p>
          <p className="text-md mb-2">
            Estimated Duration: {interviewDetails.estimatedDuration} minutes
          </p>
          <a
            href="#"
            className="text-blue-300 underline"
            onClick={(e) => {
              e.preventDefault();
              setShowDescriptionPopup(true);
            }}
          >
            View Job Description
          </a>
        </div>
      )}
      {showDescriptionPopup && (
        <DescriptionPopup
          title="Job Description"
          content={interviewDetails.job.description}
          onClose={() => setShowDescriptionPopup(false)}
        />
      )}
    </div>
  );
};

export default NewInterview;
