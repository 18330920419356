import { useSpeakingParticipants } from "@livekit/components-react";

const ParticipantAvatar = ({ type, color, label }) => {
  const speakingParticipants = useSpeakingParticipants();
  const participantId =
    type === 'agent'
      ? speakingParticipants.filter((participant) =>
          participant.identity.startsWith('agent')
        )[0]?.identity
      : speakingParticipants.filter(
          (participant) => !participant.identity.startsWith('agent')
        )[0]?.identity;

  // Check if the participant is currently speaking
  const isSpeaking = speakingParticipants.some(
    (participant) => participant.identity === participantId
  );

  return (
    <div className="relative flex items-center justify-center pt-32">
      {isSpeaking && (
        <div
          className="absolute rounded-full bg-opacity-50 bg-white animate-ping"
          style={{
            width: 150,
            height: 150,
          }}
        ></div>
      )}
      <div
        className={`w-40 h-40 rounded-full bg-${color}-500 flex items-center justify-center text-white text-4xl font-bold relative`}
        style={{ zIndex: 10 }}
      >
        {label}
      </div>
    </div>
  );
};

export default ParticipantAvatar;