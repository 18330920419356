import React from 'react';

const ThankYou = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-blue-900">
      <div className="text-center text-white">
        <h1 className="text-4xl font-bold mb-4">Thank You!</h1>
        <p className="text-lg">
          Your interview has been completed successfully.
        </p>
        <p className="text-md mt-2">
          We appreciate your time and effort. Our team will get back to you
          soon.
        </p>
      </div>
    </div>
  );
};

export default ThankYou;
