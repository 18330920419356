import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import JobDescription from './components/JobDescription';
import ThankYou from './components/ThankYou';
import NewInterview from './components/NewInterview';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/interview/:id" element={<Interview />} /> */}
        <Route path="/interview/:id" element={<NewInterview />} />
        <Route path="/job-description/:id" element={<JobDescription />} />
        <Route path="/thanks" element={<ThankYou />} />
      </Routes>
    </Router>
  );
}

export default App;
