import axios from 'axios';

const TOKEN_SERVER_URL = process.env.REACT_APP_TOKEN_SERVER_URL;
const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL;

export const getToken = async (interviewId) => {
  try {
    const response = await axios.post(`${TOKEN_SERVER_URL}/${interviewId}/createToken`, {});
    return response.data;
  } catch (error) {
    console.error('Error fetching token:', error);
    throw error;
  }
};

export const getInterviewDetails = async (interviewId) => {
  try {
    const response = await axios.get(`${API_SERVER_URL}/interviews/${interviewId}/full`);
    return response.data;
  } catch (error) {
    console.error('Error fetching interview details:', error);
    throw error;
  }
};
