// src/components/Home.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Home() {
  const [id, setId] = useState('');
  const navigate = useNavigate();

  const handleStartInterview = () => {
    if (id.trim()) {
      navigate(`/interview/${id}`);
    }
  };

  const handleJobDescription = () => {
    if (id.trim()) {
      navigate(`/job-description/${id}`);
    }
  };

  return (
    <div className="flex items-center justify-center h-screen bg-blue-900">
      <div className="bg-white shadow-md rounded-lg p-8 w-96 text-center">
        <label className="block text-gray-700 text-lg font-semibold mb-4">
          Enter the Interview ID Below
        </label>
        <input
          type="text"
          placeholder="Enter ID"
          value={id}
          onChange={(e) => setId(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
        />
        <div className="flex justify-between mt-4">
          <button
            onClick={handleStartInterview}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            Start Interview
          </button>
          <button
            onClick={handleJobDescription}
            className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
          >
            Job Description
          </button>
        </div>
      </div>
    </div>
  );
}

export default Home;
